




import {Vue, Component} from 'vue-property-decorator'

@Component({name: 'Loader'})
export default class Loader extends Vue {
  public spinnerUrl = 'img/loader.gif'
}
